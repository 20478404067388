<template>
  <RaiSetup
    class="task-lists-setup"
    :title="$t('daybook.setup.taskListSetup.title')"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <TaskList :tasks="taskLists" draggable editable v-on="$listeners" />
    <template #action>
      <TaskStatus class="ml-6" :status="status" />
      <v-spacer />
      <v-skeleton-loader type="button" :loading="$attrs.loading">
        <VxBtn class="mr-2" text @click="$emit('new')">
          <v-icon left>$add</v-icon>
          <span>{{ $t("daybook.setup.taskListSetup.newListText") }}</span>
        </VxBtn>
      </v-skeleton-loader>
    </template>
  </RaiSetup>
</template>

<script>
import TaskList from "./TaskList.vue";
import TaskStatus from "./TaskStatus.vue";
import { RaiSetup, VxBtn } from "@/core-ui";

export default {
  name: "TaskListsSetup",
  components: { RaiSetup, TaskList, TaskStatus, VxBtn },
  inheritAttrs: false,
  props: {
    taskLists: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.task-lists-setup {
  .rai-setup-content:not(.rai-setup-content--loading)
    > .v-expansion-panel-content__wrap {
    padding: 0 0px 16px 0px;
  }
}
</style>
