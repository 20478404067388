<template>
  <v-list-item
    :class="['task-list-item', classes]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list-item-icon v-if="draggable" class="task-list-item__drag-handle">
      <v-icon>$dragHandle</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ task.name }}</v-list-item-title>
      <v-list-item-subtitle v-if="isMobile">
        {{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <div v-if="isDesktop" class="task-list-item__details">
      <v-chip v-if="hasNoTasks" outlined small color="warning darken-2">
        {{ $t("daybook.setup.taskListItem.noTasksText") }}
      </v-chip>
      <v-chip v-if="repeatsFormatted" outlined small>
        <v-icon small left>$repeat</v-icon>
        {{ repeatsFormatted }}
      </v-chip>
      <v-chip v-if="task.dueTime" outlined small>
        <v-icon small left>$clock</v-icon>
        {{ dueTimeFormatted }}
      </v-chip>
    </div>
    <template v-if="editable">
      <v-list-item-action>
        <v-btn icon @click.stop="$emit('edit', task)">
          <v-icon>$edit</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action class="ml-0">
        <v-btn icon @click.stop="$emit('delete', task)">
          <v-icon>$delete</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>
import { useResponsiveness } from "@/core-ui";
import { useTaskRepeat } from "../mixins/useTaskRepeat";
import { useTaskDueTime } from "../mixins/useTaskDueTime";

export default {
  name: "TaskListItem",
  mixins: [useTaskRepeat(), useTaskDueTime(), useResponsiveness()],
  props: {
    task: {
      type: Object,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "task-list-item--draggable": this.draggable,
      };
    },
    hasNoTasks() {
      return Array.isArray(this.task.tasks) && this.task.tasks.length === 0;
    },
    subtitle() {
      return [
        this.hasNoTasks && this.$t("daybook.setup.taskListItem.noTasksText"),
        this.repeatsFormatted,
        this.dueTimeFormatted,
      ]
        .filter((value) => value)
        .join(", ");
    },
  },
};
</script>

<style lang="scss">
.task-list-item {
  & > .task-list-item__drag-handle {
    align-self: center;
    margin-right: 16px !important;
    cursor: move;
  }

  .task-list-item__details {
    display: inline-flex;

    .v-chip {
      margin-right: 8px;
    }
  }
}
</style>
