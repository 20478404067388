<template>
  <v-list-group
    v-model="active"
    :class="['task-list-group', classes]"
    :ripple="false"
  >
    <template #activator>
      <TaskListItem class="px-0" v-bind="$props" v-on="$listeners" />
    </template>
    <v-divider />
    <div class="task-list-group__items">
      <template v-for="(item, idx) in task.tasks">
        <TaskListItem :key="item.id" :task="item" />
        <v-divider
          v-if="task.tasks.length - 1 !== idx"
          :key="item.id + '_divider'"
        />
      </template>
    </div>
  </v-list-group>
</template>

<script>
import TaskListItem from "./TaskListItem.vue";

export default {
  name: "TaskListGroup",
  components: { TaskListItem },
  props: {
    task: {
      type: Object,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({ active: null }),
  computed: {
    classes() {
      return { "elevation-24": this.active };
    },
  },
};
</script>

<style lang="scss">
.task-list-group {
  .task-list-group__items {
    padding: 1rem;
  }

  .v-list-group__header__append-icon {
    min-width: unset !important;
    margin-left: 8px !important;
  }
}
</style>
