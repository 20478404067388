<template>
  <TaskForm :disabled="creating" @submit="onSubmit" />
</template>

<script>
import TaskForm from "./components/TaskForm.vue";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { TASK_LIST_CREATE, TASK_LISTS_QUERY } from "./graphql";
import { mutationUpdate } from "@/utils/graphql";

export default {
  name: "NewTaskListView",
  components: { TaskForm },
  mixins: [hasSnackbarAccess],
  props: {
    storeId: {
      type: [String, Number],
      required: true,
    },
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({ creating: false }),
  methods: {
    async onSubmit({ values, resolve, setErrors }) {
      try {
        this.creating = true;
        const response = await this.$apollo.mutate({
          mutation: TASK_LIST_CREATE,
          variables: { input: { ...values, id: undefined } },
          update: mutationUpdate({
            query: TASK_LISTS_QUERY,
            queryVariables: { storeId: this.storeId },
            mutationProp: "createTaskList",
            mutationObjectProp: "taskList",
            queryListProp: "allTaskLists",
          }),
        });

        const { taskList, errors } = response.data.createTaskList;

        if (errors.length) {
          setErrors(errors);
          return;
        }

        this.showSnackbar({
          text: this.$t("daybook.setup.newTaskList.submit.submitSuccess", {
            name: taskList.name,
          }),
        });
        this.$router.replace({
          name: this.raiLinkTo.editTaskList,
          params: { taskListId: taskList.id },
        });
        resolve();
      } catch (error) {
        this.showSnackbar({
          text: this.$t("daybook.setup.newTaskList.submit.submitError", {
            error: error,
          }),
        });
      } finally {
        this.creating = false;
      }
    },
  },
};
</script>
