<template>
  <v-list class="task-list">
    <Draggable
      v-model="localTasks"
      handle=".task-list-item__drag-handle"
      chosen-class="elevation-24"
      @change="reposition"
    >
      <v-slide-y-transition group>
        <component
          :is="getComponentFor(task)"
          v-for="(task, idx) in localTasks"
          :key="idx"
          :task="task"
          :draggable="draggable"
          :editable="editable"
          v-on="$listeners"
        />
      </v-slide-y-transition>
    </Draggable>
  </v-list>
</template>

<script>
import TaskListItem from "./TaskListItem.vue";
import TaskListGroup from "./TaskListGroup.vue";
import Draggable from "vuedraggable";

export default {
  name: "TaskList",
  components: { Draggable, TaskListItem, TaskListGroup },
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    localTasks: [],
  }),
  watch: {
    tasks: {
      deep: true,
      immediate: true,
      handler(values) {
        this.localTasks = values
          .slice() // -> shallow copy so we don't sort on provided array
          .sort((a, b) => a.position - b.position);
      },
    },
  },
  methods: {
    getComponentFor(task) {
      return Array.isArray(task.tasks) ? "TaskListGroup" : "TaskListItem";
    },
    reposition() {
      this.$emit(
        "reposition",
        this.localTasks.map((x, index) => ({
          id: x.id,
          position: index + 1,
        }))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.elevation-24 {
  transition: box-shadow 0.2s ease-in;
}
</style>
