<template>
  <RaiSetups v-model="activeSetup">
    <TaskListsSetup
      :task-lists="taskLists"
      :loading="isLoadingTaskLists"
      :status="taskListsStatus"
      @new="onTaskListNew"
      @edit="onTaskListEdit"
      @delete="onTaskListDelete"
      @reposition="onTaskListReposition"
    />
  </RaiSetups>
</template>

<script>
import { RaiSetups } from "@/core-ui";
import { mutationDelete } from "@/utils/graphql";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import TaskListsSetup from "./components/TaskListsSetup.vue";
import {
  TASK_LISTS_QUERY,
  TASK_LIST_REPOSITION,
  TASK_LIST_DELETE,
} from "./graphql";

export default {
  name: "DaybookSetupView",
  components: { RaiSetups, TaskListsSetup },
  mixins: [hasSnackbarAccess],
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({ activeSetup: 0, taskListsStatus: null }),
  apollo: {
    taskLists: {
      query: TASK_LISTS_QUERY,
      // TODO: find feasible way to update cache when change has happend in other view
      fetchPolicy: "cache-and-network",
      update: (data) => data.allTaskLists ?? [],
      variables() {
        return { storeId: this.storeId };
      },
      skip() {
        return !this.storeId;
      },
    },
  },
  computed: {
    isLoadingTaskLists() {
      return this.$apollo.queries.taskLists.loading && !this.taskListsStatus;
    },
  },
  methods: {
    onTaskListNew() {
      this.$router.push({
        name: this.raiLinkTo.newTaskList,
      });
    },
    onTaskListEdit(taskList) {
      this.$router.push({
        name: this.raiLinkTo.editTaskList,
        params: { taskListId: taskList.id },
      });
    },
    async onTaskListReposition(values) {
      try {
        this.taskListsStatus = this.$t(
          "daybook.setup.index.taskListReposition.status"
        );
        await this.$apollo.mutate({
          mutation: TASK_LIST_REPOSITION,
          variables: {
            storeId: this.storeId,
            input: values,
          },
        });
      } catch (error) {
        this.showSnackbar({
          text: this.$t(
            "daybook.setup.index.taskListReposition.repositionError",
            {
              error: error,
            }
          ),
        });
      } finally {
        this.taskListsStatus = null;
      }
    },
    async onTaskListDelete({ id, name }) {
      const confirm = await this.$dialog.confirm({
        title: this.$t("daybook.setup.index.taskListDelete.title"),
        message: this.$t("daybook.setup.index.taskListDelete.message", {
          name: name,
        }),
      });

      if (!confirm) {
        return;
      }

      try {
        await this.$apollo.mutate({
          mutation: TASK_LIST_DELETE,
          variables: { id },
          update: mutationDelete({
            query: TASK_LISTS_QUERY,
            queryVariables: { storeId: this.storeId },
            queryListProp: "allTaskLists",
            mutationProp: "deleteTaskList",
            mutationObjectProp: "taskList",
          }),
        });

        this.showSnackbar({
          text: this.$t("daybook.setup.index.taskListDelete.deleteSuccess", {
            name: name,
          }),
        });
      } catch (error) {
        this.showSnackbar({
          text: this.$t("daybook.setup.index.taskListDelete.deleteError", {
            error: error,
          }),
        });
      }
    },
  },
};
</script>
